<template>
    <form
        class="space-y-4 md:space-y-6"
        @submit.prevent="submitForm"
    >
        <div>
            <label class="block text-white mb-1">Email</label>
            <input
                v-model="fastOrderForm.email"
                type="email"
                name="email"
                autocomplete="email"
                class="w-full p-2 md:p-3 bg-white text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:border-flushing-pink text-base"
                :class="{ 'border-red-500': fastOrderForm.errors.has('email') }"
                placeholder="Your email address"
                @blur="validateField('email')"
            >
            <p
                v-if="fastOrderForm.errors.has('email')"
                class="text-red-500 text-sm mt-1"
            >
                {{ fastOrderForm.errors.get('email') }}
            </p>
        </div>

        <div>
            <label class="block text-white mb-1">Phone</label>
            <input
                :value="phoneFormatted"
                inputmode="numeric"
                name="phone"
                autocomplete="tel"
                class="w-full p-2 md:p-3 bg-white text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:border-flushing-pink text-base"
                :class="{
                    'border-red-500': fastOrderForm.errors.has('phone') || !isPhoneValid && phoneFormatted,
                    'border-green-500': isPhoneValid && phoneFormatted && !fastOrderForm.errors.has('phone')
                }"
                placeholder="Your phone number"
                @input="(e) => onPhoneInput(e.target.value)"
                @keypress="isNumber($event)"
                @blur="validatePhone"
            >
            <p
                v-if="fastOrderForm.errors.has('phone')"
                class="text-red-500 text-sm mt-1"
            >
                {{ fastOrderForm.errors.get('phone') }}
            </p>
            <p
                v-else-if="phoneError"
                class="text-red-500 text-sm mt-1"
            >
                {{ phoneError }}
            </p>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div>
                <label class="block text-white mb-1">First Name</label>
                <input
                    v-model="fastOrderForm.first_name"
                    type="text"
                    name="first_name"
                    autocomplete="given-name"
                    class="w-full p-2 md:p-3 bg-white text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:border-flushing-pink text-base"
                    :class="{ 'border-red-500': fastOrderForm.errors.has('first_name') }"
                    placeholder="First name"
                    @blur="validateField('first_name')"
                >
                <p
                    v-if="fastOrderForm.errors.has('first_name')"
                    class="text-red-500 text-sm mt-1"
                >
                    {{ fastOrderForm.errors.get('first_name') }}
                </p>
            </div>
            <div>
                <label class="block text-white mb-1">Last Name</label>
                <input
                    v-model="fastOrderForm.last_name"
                    type="text"
                    name="last_name"
                    autocomplete="family-name"
                    class="w-full p-2 md:p-3 bg-white text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:border-flushing-pink text-base"
                    :class="{ 'border-red-500': fastOrderForm.errors.has('last_name') }"
                    placeholder="Last name"
                    @blur="validateField('last_name')"
                >
                <p
                    v-if="fastOrderForm.errors.has('last_name')"
                    class="text-red-500 text-sm mt-1"
                >
                    {{ fastOrderForm.errors.get('last_name') }}
                </p>
            </div>
        </div>

        <button
            type="submit"
            class="w-full bg-flushing-pink text-white rounded-full py-3 md:py-4 font-bold text-lg
                  hover:opacity-90 transition-opacity duration-300
                  disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="fastOrderForm.processing || !isPersonalInfoValid"
        >
            <template v-if="!fastOrderForm.processing">
                Claim Free Trial
            </template>
            <div
                v-else
                class="flex items-center justify-center"
            >
                <svg
                    class="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    />
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
                Validating...
            </div>
        </button>

        <!-- Non-input field errors -->
        <div
            v-if="globalError"
            class="mt-4"
        >
            <p class="text-red-500 text-sm text-center">
                {{ globalError }}
            </p>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive, computed, watch } from 'vue';
    import Form from 'root-js/utils/API/Form';
    import { parsePhoneNumberFromString, AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
    import { isNumber } from 'root-js/helpers/number.helper';
    import { useGtag } from 'vue-gtag-next';

    const emit = defineEmits(['personal-info-validated', 'validation-error']);
    const { event } = useGtag();

    const props = defineProps({
        quantity: {
            type: Number,
            required: true
        },
        appliedCode: {
            type: Object,
            default: null
        }
    });

    const phoneFormatted = ref('');
    const globalError = ref('');

    const isPhoneValid = computed(() => {
        try {
            if (!phoneFormatted.value) {
                return true;
            } // Don't show error when empty
            return isValidPhoneNumber(phoneFormatted.value, 'US');
        } catch {
            return false;
        }
    });

    const phoneError = computed(() => {
        if (!phoneFormatted.value) {
            return '';
        }
        if (!isPhoneValid.value) {
            return 'Please enter a valid US phone number';
        }
        return '';
    });

    const onPhoneInput = phone => {
        phoneFormatted.value = new AsYouType('US').input(phone);
        const phoneNumber = parsePhoneNumberFromString(phone, 'US');
        if (phoneNumber) {
            fastOrderForm.phone = phoneNumber.nationalNumber;

            // Clear form error if phone becomes valid
            if (isPhoneValid.value && fastOrderForm.errors.has('phone')) {
                fastOrderForm.errors.clear('phone');
            }
        }
    };

    const validatePhone = () => {
        if (!phoneFormatted.value) {
            fastOrderForm.errors.record({ phone: [ 'Phone number is required' ]}, true);
            return false;
        }
        if (!isPhoneValid.value) {
            fastOrderForm.errors.record({ phone: [ 'Please enter a valid US phone number' ]}, true);
            return false;
        }
        fastOrderForm.errors.clear('phone');
        return true;
    };

    const fastOrderForm = reactive(new Form('validate-fast-order', {
        first_name: { value: '', rule: 'required|min:2', mutator: value => value.trim() },
        last_name: { value: '', rule: 'required|min:2', mutator: value => value.trim() },
        email: { value: '', rule: 'required|email', mutator: value => value.trim().toLowerCase() },
        phone: { value: '', rule: 'required' },
        country_code: { value: 'US', rule: 'required' }
    }, null, null, window.api, { autoReset: false }));

    const isPersonalInfoValid = computed(() => {
        const hasRequiredFields = fastOrderForm.first_name
            && fastOrderForm.last_name
            && fastOrderForm.email
            && fastOrderForm.phone;

        // Always check for errors, even as user is typing
        const hasNoErrors = !fastOrderForm.errors.any();

        return hasRequiredFields && hasNoErrors;
    });

    const validateField = field => {
        const validationResult = fastOrderForm.validate(field);
        if (validationResult === true) {
            fastOrderForm.errors.clear(field);
        }
    };

    const validatePersonalInfo = () => {
        const fieldsToValidate = [
            'first_name', 'last_name', 'email', 'phone'
        ];

        fieldsToValidate.forEach(field => validateField(field));
        validatePhone();

        return !fieldsToValidate.some(field => fastOrderForm.errors.has(field));
    };

    const submitForm = async () => {
        globalError.value = '';

        if (!validatePersonalInfo()) {
            return;
        }

        try {

            // Use Form's submit method
            await fastOrderForm.submit();

            // If validation is successful, emit event with personal information
            emit('personal-info-validated', {
                first_name: fastOrderForm.first_name,
                last_name: fastOrderForm.last_name,
                email: fastOrderForm.email,
                phone: fastOrderForm.phone,
                country_code: fastOrderForm.country_code
            });

            // Track add_shipping_info event preparation
            event('add_shipping_info', {
                currency: 'USD',
                value: (props.quantity + 1) * 15,
                shipping_tier: 'standard',
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: 15,
                    quantity: props.quantity + 1
                } ]
            });
        } catch (error) {
            console.error('Validation Error:', error);

            // Handle error response from API
            if (error.response?.data?.error) {
                const apiError = error.response.data.error;

                // If there are field-specific errors in the meta field
                if (apiError.meta) {

                    // Check for email error
                    if (apiError.meta.email && apiError.meta.email.length > 0) {
                        fastOrderForm.errors.record({ email: apiError.meta.email }, true);
                    }

                    // Check for phone error
                    if (apiError.meta.phone && apiError.meta.phone.length > 0) {
                        fastOrderForm.errors.record({ phone: apiError.meta.phone }, true);
                    }

                    // If we have specific field errors, use the general detail message
                    globalError.value = apiError.detail || 'Please check the highlighted fields for errors.';
                } else {

                    // Use detail or title for general errors
                    globalError.value = apiError.detail || apiError.title || 'Validation failed. Please check your information.';
                }
            } else {

                // Fallback for non-API errors
                globalError.value = error.message || 'Validation failed. Please check your information.';
            }

            emit('validation-error', error);
        }
    };

    /* eslint-disable no-undef */
    defineExpose({
        validatePersonalInfo,
        getFormData: () => {
            return {
                first_name: fastOrderForm.first_name,
                last_name: fastOrderForm.last_name,
                email: fastOrderForm.email,
                phone: fastOrderForm.phone,
                country_code: fastOrderForm.country_code
            };
        }
    });
    /* eslint-enable no-undef */

    // Keep quantity watcher for GA events
    watch(() => props.quantity, newQuantity => {

    // For GA events
    });

    // Add a watcher for each form field to validate in real time
    watch(() => fastOrderForm.first_name, value => {
        validateField('first_name');
    });

    watch(() => fastOrderForm.last_name, value => {
        validateField('last_name');
    });

    watch(() => fastOrderForm.email, value => {
        validateField('email');
    });
</script>

<style scoped>
.bg-navy-blue {
    background-color: #000033;
}

.bg-navy-blue-light {
    background-color: #000044;
}

.bg-flushing-pink {
    background-color: #FF1493;
}

/* Reset color to original dark gray */
input {
    color: #333333 !important;
}
</style>
