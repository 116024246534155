<template>
    <div class="mb-6 coupon-section">
        <!-- Code Input Section -->
        <div class="space-y-4">
            <!-- Code Input -->
            <div
                v-if="!codeApplied"
                class="flex gap-2"
            >
                <input
                    v-model="codeInput"
                    type="text"
                    placeholder="Enter coupon or referral code"
                    class="flex-1 p-3 rounded-md bg-navy-blue-light text-white border border-gray-600
                           focus:outline-none focus:border-flushing-pink"
                    :disabled="isLoadingCode"
                >
                <button
                    class="px-4 py-2 bg-flushing-pink text-white rounded-md hover:opacity-90
                           disabled:opacity-50 disabled:cursor-not-allowed"
                    :disabled="isLoadingCode || !codeInput"
                    @click="applyCode"
                >
                    <template v-if="!isLoadingCode">
                        Apply
                    </template>
                    <template v-else>
                        <svg
                            class="animate-spin h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            />
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                        </svg>
                    </template>
                </button>
            </div>
        </div>

        <!-- Applied Code Tag -->
        <div
            v-if="codeApplied"
            class="flex items-center mt-4"
        >
            <div class="bg-navy-blue-light rounded-full px-4 py-2 flex items-center gap-2">
                <span class="text-white">
                    {{ appliedCode.code }}
                    <template v-if="appliedCode.type === 'coupon' && appliedCode.discount">
                        - {{ formatDiscount(appliedCode.discount) }}
                    </template>
                    <template v-else-if="appliedCode.type === 'referral' && appliedCode.credit > 0">
                        - ${{ (appliedCode.credit / 100).toFixed(2) }} credit
                    </template>
                </span>
                <i
                    class="icon icon-cross rounded-full bg-flushing-pink text-white p-1 cursor-pointer text-xs"
                    @click="removeCode"
                />
            </div>
        </div>

        <!-- Error Messages -->
        <p
            v-if="codeError || externalError"
            class="text-red-400 text-sm mt-2"
        >
            {{ externalError || 'Invalid code. Please check and try again.' }}
        </p>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import Form from 'root-js/utils/API/Form';
    import { useGtag } from 'vue-gtag-next';
    import { hasProperty } from 'root-js/utils/object-utils';

    const props = defineProps({
        quantity: {
            type: Number,
            required: true
        },
        initialCode: {
            type: String,
            default: ''
        },
        externalError: {
            type: String,
            default: ''
        }
    });

    const route = useRoute();
    const emit = defineEmits(['code-applied', 'code-removed']);
    const { event } = useGtag();

    const codeInput = ref('');
    const codeApplied = ref(false);
    const codeError = ref(false);
    const isLoadingCode = ref(false);
    const appliedCode = ref(null);

    // Use onMounted instead of watch for initial code
    onMounted(() => {
        if (props.initialCode) {
            codeInput.value = props.initialCode;
            applyCode();
        }
    });

    // Keep the watch for subsequent changes
    watch(() => props.initialCode, newCode => {
        if (newCode && newCode !== codeInput.value) {
            codeInput.value = newCode;
            applyCode();
        }
    });

    function formatDiscount(discount) {
        if (discount.percent_off) {
            return `${discount.percent_off}% off`;
        }
        if (discount.amount_off) {
            return `$${(discount.amount_off / 100).toFixed(2)} off`;
        }
        if (discount.amount) {
            return `$${(discount.amount / 100).toFixed(2)} credit`;
        }
        return '';
    }

    const applyCode = async () => {
        if (!codeInput.value || isLoadingCode.value) {
            return;
        }

        isLoadingCode.value = true;
        codeError.value = false;

        try {
            const verifyForm = new Form('validate-discount-code', {
                quantity: { value: props.quantity + 1 },
                code: { value: codeInput.value }
            });

            const response = await verifyForm.submit();

            if (response.data) {
                codeApplied.value = true;

                // Determine if it's a coupon or referral based on response
                const codeType = hasProperty(response.data, 'referrer_id') ? 'referral' : 'coupon';

                appliedCode.value = {
                    type: codeType,
                    code: codeInput.value,
                    id: response.data.id,
                    discount: codeType === 'coupon' ? response.data : null,
                    referrerId: codeType === 'referral' ? response.data.referrer_id : null,
                    credit: codeType === 'referral' ? response.data.referee_credit : 0
                };

                emit('code-applied', appliedCode.value);

                // Track select_promotion event
                event('select_promotion', {
                    promotion_id: response.data.id || `code_${codeInput.value}`,
                    promotion_name: response.data.name || codeInput.value,
                    creative_name: `${codeType}_entry`,
                    creative_slot: 'code_box',
                    items: [ {
                        item_id: 'space_box',
                        item_name: 'Space Box',
                        quantity: props.quantity + 1
                    } ]
                });
            }
        } catch (error) {
            console.error('Code verification failed:', error);
            codeError.value = true;
            codeApplied.value = false;
            emit('code-applied', null);

            // Clear the coupon code if it was from query and failed
            if (hasProperty(route.query, 'coupon') && route.query.coupon === codeInput.value) {
                codeInput.value = '';
            }
            if (hasProperty(route.query, 'code') && route.query.code === codeInput.value) {
                codeInput.value = '';
            }
            if (hasProperty(route.query, 'referral') && route.query.referral === codeInput.value) {
                codeInput.value = '';
            }
        } finally {
            isLoadingCode.value = false;
        }
    };

    const removeCode = () => {
        codeApplied.value = false;
        codeInput.value = '';
        codeError.value = false;
        emit('code-removed');
        appliedCode.value = null;
    };
</script>

<style scoped>
.bg-navy-blue {
    background-color: #000033;
}

.bg-navy-blue-light {
    background-color: #000044;
}

.bg-flushing-pink {
    background-color: #FF1493;
}
</style>
