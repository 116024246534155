<template>
    <div>
        <!-- Show both discounts separately if both are applied -->
        <div
            v-if="couponDiscount > 0"
            class="flex justify-between items-center text-white mb-2"
        >
            <div class="flex flex-col">
                <span class="text-white">Coupon Discount</span>
                <span class="text-sm text-green-500">
                    {{ formatDiscountText(couponDetails) }}
                </span>
            </div>
            <span class="text-green-500">-${{ couponDiscount.toFixed(2) }}</span>
        </div>

        <div
            v-if="referralApplied && referralCredit > 0"
            class="flex justify-between items-center text-white mb-2"
        >
            <div class="flex flex-col">
                <span class="text-white">Referral Credit</span>
                <span class="text-sm text-green-500">Applied to first order</span>
            </div>
            <span class="text-green-500">-${{ referralDiscount.toFixed(2) }}</span>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';

    const props = defineProps({
        quantity: {
            type: Number,
            required: true
        },
        monthlyRate: {
            type: Number,
            required: true
        },
        couponDetails: {
            type: Object,
            default: null
        },
        minimumMonths: {
            type: Number,
            default: 3
        },
        referralCredit: {
            type: Number,
            default: 0
        },
        referralApplied: {
            type: Boolean,
            default: false
        }
    });

    const monthlyTotal = computed(() => props.quantity * props.monthlyRate);
    const totalBeforeDiscount = computed(() => monthlyTotal.value);

    // Calculate coupon discount
    const couponDiscount = computed(() => {
        if (!props.couponDetails) {
            return 0;
        }

        if (props.couponDetails.percent_off) {
            return totalBeforeDiscount.value * (props.couponDetails.percent_off / 100);
        }

        if (props.couponDetails.amount_off) {
            return props.couponDetails.amount_off / 100;
        }

        if (props.couponDetails.amount) {
            const amountInDollars = props.couponDetails.amount / 100;
            return Math.min(amountInDollars, totalBeforeDiscount.value);
        }

        return 0;
    });

    // Calculate referral discount
    const referralDiscount = computed(() => props.referralApplied ? props.referralCredit / 100 : 0);

    // Calculate total discounts
    const totalDiscounts = computed(() => couponDiscount.value + referralDiscount.value);

    // Calculate final total
    const finalTotal = computed(() => Math.max(0, totalBeforeDiscount.value - totalDiscounts.value));

    function formatDiscountText(discount) {
        if (discount.percent_off) {
            return `${discount.name || discount.code} (${discount.percent_off}% off)`;
        }
        if (discount.amount_off) {
            return `${discount.name || discount.code} ($${(discount.amount_off / 100).toFixed(2)} off)`;
        }
        if (discount.amount) {
            return `${discount.name || discount.code} ($${(discount.amount / 100).toFixed(2)} credit)`;
        }
        return discount.name || discount.code;
    }
</script>

<style scoped>
.bg-navy-blue {
    background-color: #000033;
}

.bg-navy-blue-light {
    background-color: #000044;
}

.bg-flushing-pink {
    background-color: #FF1493;
}
</style>
