<template>
    <div class="p-5 rounded-lg bg-indigo-950 mb-8">
        <p class="text-white text-base font-normal mb-6">
            Want additional boxes? Add more with your first order!
        </p>

        <div class="flex justify-between items-center mb-5">
            <h3 class="text-white text-xl font-semibold">
                Space Box
            </h3>
            <span class="text-white font-medium">$ 15.00 / month</span>
        </div>

        <div class="flex items-center">
            <button
                class="w-9 h-9 rounded-lg bg-indigo-900 flex items-center justify-center text-white font-medium text-lg"
                @click="decreaseQuantity"
            >
                -
            </button>
            <span class="text-white text-2xl font-medium w-8 text-center">{{ quantity }}</span>
            <button
                class="w-9 h-9 rounded-lg bg-indigo-900 flex items-center justify-center text-white font-medium text-lg"
                @click="increaseQuantity"
            >
                +
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, watch } from 'vue';
    import { useGtag } from 'vue-gtag-next';

    const props = defineProps({
        monthlyRate: {
            type: Number,
            default: 15.00
        },
        modelValue: {
            type: Number,
            default: 0
        }
    });

    const emit = defineEmits(['update:quantity', 'update:modelValue']);
    const { event } = useGtag();

    const quantity = ref(props.modelValue);

    // Sync with parent component if needed
    watch(() => props.modelValue, newValue => {
        quantity.value = newValue;
    });

    const monthlyTotal = computed(() => quantity.value * props.monthlyRate);

    onMounted(() => {

        // Track view_item event when component is mounted
        event('view_item', {
            currency: 'USD',
            value: props.monthlyRate,
            items: [ {
                item_id: 'space_box',
                item_name: 'Space Box',
                price: props.monthlyRate,
                quantity: 1
            } ]
        });
    });

    const decreaseQuantity = () => {
        if (quantity.value > 0) {
            quantity.value--;
            emit('update:quantity', quantity.value);
            emit('update:modelValue', quantity.value);

            // Track remove_from_cart event
            event('remove_from_cart', {
                currency: 'USD',
                value: props.monthlyRate,
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: props.monthlyRate,
                    quantity: 1
                } ]
            });
        }
    };

    const increaseQuantity = () => {
        if (quantity.value < 15) {
            quantity.value++;
            emit('update:quantity', quantity.value);
            emit('update:modelValue', quantity.value);

            // Track add_to_cart event
            event('add_to_cart', {
                currency: 'USD',
                value: props.monthlyRate,
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: props.monthlyRate,
                    quantity: 1
                } ]
            });
        }
    };
</script>

<style scoped>
.bg-indigo-950 {
    background-color: #1a1a47;
}

.bg-indigo-900 {
    background-color: #1e1e5a;
}

.bg-flushing-pink {
    background-color: #FF1493;
}
</style>
