<template>
    <form class="space-y-4">
        <div>
            <label class="block text-sm text-gray-600 mb-1">Address</label>
            <input
                v-model="shippingForm.address_line_1"
                type="text"
                name="address_line_1"
                autocomplete="address-line1"
                class="w-full p-3 border rounded-md"
                :class="{ 'border-red-500': shippingForm.errors.has('address_line_1') }"
                placeholder="Address"
                @blur="onBlur('address_line_1')"
            >
            <p
                v-if="shippingForm.errors.has('address_line_1')"
                class="text-red-500 text-sm mt-1"
            >
                {{ shippingForm.errors.get('address_line_1') }}
            </p>
        </div>

        <div>
            <label class="block text-sm text-gray-600 mb-1">Apartment, suite, etc. (optional)</label>
            <input
                v-model="shippingForm.address_line_2"
                type="text"
                name="address_line_2"
                autocomplete="address-line2"
                class="w-full p-3 border rounded-md"
                placeholder="Apartment, suite, etc."
            >
            <p
                v-if="shippingForm.errors.has('address_line_2')"
                class="text-red-500 text-sm mt-1"
            >
                {{ shippingForm.errors.get('address_line_2') }}
            </p>
        </div>

        <div class="flex gap-4">
            <div class="flex-1">
                <label class="block text-sm text-gray-600 mb-1">City</label>
                <input
                    v-model="shippingForm.city"
                    type="text"
                    name="city"
                    autocomplete="address-level2"
                    class="w-full p-3 border rounded-md"
                    :class="{ 'border-red-500': shippingForm.errors.has('city') }"
                    placeholder="City"
                    @blur="onBlur('city')"
                >
                <p
                    v-if="shippingForm.errors.has('city')"
                    class="text-red-500 text-sm mt-1"
                >
                    {{ shippingForm.errors.get('city') }}
                </p>
            </div>
            <div class="flex-1">
                <label class="block text-sm text-gray-600 mb-1">State</label>
                <select
                    v-model="shippingForm.state"
                    name="state"
                    autocomplete="address-level1"
                    class="w-full p-3 border rounded-md"
                    :class="{ 'border-red-500': shippingForm.errors.has('state') }"
                    @blur="onBlur('state')"
                    @change="validateField('state')"
                >
                    <option
                        value=""
                        disabled
                        class="text-gray-400"
                    >
                        State
                    </option>
                    <option
                        v-for="state in US_STATES"
                        :key="state.value"
                        :value="state.value"
                    >
                        {{ state.displayText }}
                    </option>
                </select>
                <p
                    v-if="shippingForm.errors.has('state')"
                    class="text-red-500 text-sm mt-1"
                >
                    {{ shippingForm.errors.get('state') }}
                </p>
            </div>
            <div class="flex-1">
                <label class="block text-sm text-gray-600 mb-1">Postal Code</label>
                <input
                    v-model="shippingForm.postal_code"
                    type="text"
                    name="postal_code"
                    autocomplete="postal-code"
                    class="w-full p-3 border rounded-md"
                    :class="{ 'border-red-500': shippingForm.errors.has('postal_code') }"
                    placeholder="10001"
                    maxlength="5"
                    @blur="onBlur('postal_code')"
                >
                <p
                    v-if="shippingForm.errors.has('postal_code')"
                    class="text-red-500 text-sm mt-1"
                >
                    {{ shippingForm.errors.get('postal_code') }}
                </p>
            </div>
        </div>
    </form>
</template>

<script setup>
    import { reactive, watch } from 'vue';
    import { US_STATES } from 'root-js/constants/address.const';
    import Form from 'root-js/utils/API/Form';

    const emit = defineEmits(['update:shipping-data', 'validate-shipping']);

    // Define form with validation rules
    const shippingForm = reactive(new Form('validate-fast-order', {
        address_line_1: { value: '', rule: 'required|min:5', mutator: value => value.trim() },
        address_line_2: { value: '', rule: '', mutator: value => value.trim() },
        state: { value: '', rule: 'required' },
        city: { value: '', rule: 'required|min:2', mutator: value => value.trim() },
        country: { value: 'US', rule: 'required' },
        postal_code: { value: '', rule: 'required', mutator: value => value.trim() }
    }, null, null, window.api, { autoReset: false }));

    // Method to validate a specific field
    function validateField(field) {

        // Don't skip validation for empty fields to ensure consistent behavior
        const validationResult = shippingForm.validate(field);
        if (validationResult === true) {
            shippingForm.errors.clear(field);
        }

        // Don't emit on every field validation to prevent recursion
        return validationResult === true;
    }

    // Method to validate all fields
    function validateAllFields() {
        const fieldsToValidate = [
            'address_line_1', 'city', 'state', 'postal_code'
        ];

        let allValid = true;
        fieldsToValidate.forEach(field => {
            const isValid = validateField(field);
            if (!isValid) {
                allValid = false;
            }
        });

        // Only emit after all fields are validated
        emitShippingData();

        return allValid;
    }

    // Method to set errors from external validation (like API response)
    function setErrors(errors) {
        if (typeof errors === 'object' && errors !== null) {
            Object.keys(errors).forEach(field => {
                if (errors[field] && errors[field].length > 0) {
                    shippingForm.errors.record({ [field]: errors[field] }, true);
                }
            });
        }

        // Re-emit shipping data with updated validation status
        emitShippingData();
    }

    // Method to handle blur events and trigger validation
    function onBlur(field) {

        // Always validate the field on blur, even if empty
        validateField(field);

        // Always emit shipping data on blur to update parent component
        emitShippingData();
    }

    // Emit shipping data when it changes
    function emitShippingData() {

        // Field is valid if it has a value AND no errors
        const requiredFields = ['address_line_1', 'city', 'state', 'postal_code'];
        const isValid = requiredFields.every(field => shippingForm[field] && !shippingForm.errors.has(field));

        emit('update:shipping-data', {
            address_line_1: shippingForm.address_line_1,
            address_line_2: shippingForm.address_line_2,
            state: shippingForm.state,
            city: shippingForm.city,
            country: shippingForm.country,
            postal_code: shippingForm.postal_code,
            isValid
        });
    }

    // Add watchers for real-time validation
    watch(() => shippingForm.address_line_1, value => {
        validateField('address_line_1');
        emitShippingData();
    });

    watch(() => shippingForm.city, value => {
        validateField('city');
        emitShippingData();
    });

    watch(() => shippingForm.state, value => {

        // Always validate state when it changes, even if empty
        validateField('state');
        emitShippingData();
    });

    watch(() => shippingForm.postal_code, value => {
        validateField('postal_code');
        emitShippingData();
    });

    /* eslint-disable no-undef */
    defineExpose({
        validateAllFields,
        setErrors,
        getFormValues: () => {
            return {
                address_line_1: shippingForm.address_line_1,
                address_line_2: shippingForm.address_line_2,
                state: shippingForm.state,
                city: shippingForm.city,
                country: shippingForm.country,
                postal_code: shippingForm.postal_code
            };
        }
    });
/* eslint-enable no-undef */
</script>
