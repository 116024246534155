<template>
    <div class="w-full">
        <form
            id="payment-form"
            data-rewardful="true"
            @submit.prevent="handleSubmit"
        >
            <!-- Payment Element will be inserted here -->
            <div
                id="payment-element"
                ref="paymentElementContainer"
                class="mb-6"
            >
                <div
                    v-if="isInitializing"
                    class="text-gray-500 text-center py-4"
                >
                    Loading payment form...
                </div>
            </div>

            <button
                type="submit"
                class="w-full bg-flushing-pink text-white rounded-full py-4 flex items-center justify-center
                       hover:opacity-90 transition-opacity duration-300
                       disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="isLoading"
            >
                <template v-if="!isLoading">
                    Checkout
                </template>
                <div
                    v-else
                    class="flex items-center"
                >
                    <svg
                        class="animate-spin h-5 w-5 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        />
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                    Processing...
                </div>
            </button>

            <p
                v-if="errorMessage"
                class="mt-4 text-red-500 text-sm text-center"
            >
                {{ errorMessage }}
            </p>
        </form>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch, computed, onBeforeUnmount, onBeforeUpdate } from 'vue';
    import { stripe } from 'root-js/services/StripeService';
    import { useRouter } from 'vue-router';
    import Form from 'root-js/utils/API/Form';
    import { useStore } from 'vuex';
    import { useGtag } from 'vue-gtag-next';


    const props = defineProps({
        fastOrderRequestId: String,
        finalTotal: {
            type: Number,
            required: true,
            validator: value => {

                // Ensure max 2 decimal places
                const decimalPart = value.toString().split('.')[1] || '';
                return decimalPart.length <= 2;
            }
        },
        fullOrderData: {
            type: Object,
            required: true
        }
    });

    const router = useRouter();
    const store = useStore();
    const emit = defineEmits(['payment-error', 'shipping-validation-error', 'promotion-error']);

    const isLoading = ref(false);
    const errorMessage = ref('');
    const isElementMounted = ref(false);
    const paymentElementContainer = ref(null);

    // Reactive fastOrderRequestId
    const currentFinalTotal = computed(() => props.finalTotal);

    const { event } = useGtag();

    // Pre-mount payment element
    onMounted(async () => {
        try {
            await initPaymentElement();

            // Track add_payment_info event
            event('add_payment_info', {
                currency: 'USD',
                value: props.finalTotal,
                payment_type: 'credit_card',
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: props.finalTotal
                } ]
            });
        } catch (error) {
            errorMessage.value = 'Failed to load payment form';
        }
    });


    const initPaymentElement = async () => {
        try {
            if (isNaN(props.finalTotal)) {
                throw new Error('Invalid payment amount');
            }

            await stripe.initDeferredElements('subscription',
                                              0,
                                              'usd');
            stripe.paymentElement.mount('#payment-element');
            isElementMounted.value = true;

        } catch (error) {
            errorMessage.value = 'Failed to load payment form. Please try refreshing.';
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!isElementMounted.value) {
            return;
        }

        // Basic validation before submission
        const requiredFields = ['first_name', 'last_name', 'email', 'phone', 'address_line_1', 'city', 'state', 'postal_code'];
        const missingFields = [];

        requiredFields.forEach(field => {
            const value = props.fullOrderData[field]?.value;
            const isValid = value && value.toString().trim() !== '';

            if (!isValid) {
                missingFields.push(field);
            }
        });

        if (missingFields.length > 0) {
            errorMessage.value = 'Please complete all required information before proceeding.';
            return;
        }

        isLoading.value = true;
        errorMessage.value = '';

        try {

            // 1. Submit to Stripe elements
            const { error: submitError } = await stripe.submitElements();
            if (submitError) {
                throw submitError;
            }

            // Extract the rewardful referral ID if available
            const rewardfulInput = document.querySelector('input[name="referral"]');
            const rewardfulReferralId = rewardfulInput ? rewardfulInput.value : null;

            // 2. Call open-fast-order with all form data
            const openFastOrderForm = new Form('open-fast-order', {

                // We'll get all the data from props.fullOrderData
                ...props.fullOrderData,
                device_uid: { value: crypto.randomUUID() },
                device_os: { value: 'web' },
                rewardful_referral_id: { value: rewardfulReferralId || '' }
            });

            const response = await openFastOrderForm.submit(true);

            // 3. Get payment details from response
            const { client_secret: clientSecret, intent_type: intentType } = response.data;

            // 4. Confirm intent with retrieved values
            const result = await stripe.confirmIntent(clientSecret, intentType, window.location.href);

            if (result.error) {
                throw result.error; // Handle in catch block
            }

            // Call authentication API with the fast_order_request_id from the response
            const authForm = new Form('complete-fast-order', { fast_order_request_id: { value: response.data.fast_order_request_id || '' }});

            const authResponse = await authForm.submit();

            event('purchase', {
                transaction_id: result.paymentIntent?.id || `order_${Date.now()}`,
                currency: 'USD',
                value: parseFloat(props.finalTotal.toFixed(2)),
                items: [ {
                    item_id: 'space_box',
                    item_name: 'Space Box',
                    price: props.finalTotal
                } ]
            });

            // Save tokens
            localStorage.setItem('token', authResponse.data.token);
            localStorage.setItem('refreshToken', authResponse.data.refresh_token);

            // Set auth in Vuex store
            await store.commit('auth/SET_AUTH', {
                token: authResponse.data.token,
                refresh_token: authResponse.data.refresh_token
            });

            // Wait for a moment to ensure token is processed
            await new Promise(resolve => setTimeout(resolve, 500));

            // Navigate to your-space
            router.push({
                path: '/your-space',
                query: { fromFastOrder: 'true' }
            });
        } catch (error) {

            // Handle error response from API
            if (error.response?.data?.error) {
                const apiError = error.response.data.error;

                // Check for promotion/coupon related errors
                const promotionFields = [
                    'promotion_code_id',
                    'referrer_id',
                    'rewardful_referral_id',
                    'credit_balance_promotion_code_id'
                ];

                let hasPromotionError = false;
                const promotionErrors = {};

                // Collect all promotion-related errors
                if (apiError.meta) {
                    promotionFields.forEach(field => {
                        if (apiError.meta[field] && apiError.meta[field].length > 0) {
                            promotionErrors[field] = apiError.meta[field];
                            hasPromotionError = true;
                        }
                    });

                    // If there are promotion-related errors, emit a specific event
                    if (hasPromotionError) {
                        emit('promotion-error', promotionErrors);
                    }

                    // Check for shipping address errors and emit them
                    const addressErrors = {};
                    const addressFields = [
                        'address_line_1', 'address_line_2', 'city',
                        'state', 'postal_code', 'country'
                    ];

                    // Collect all address-related errors
                    let hasAddressError = false;
                    addressFields.forEach(field => {
                        if (apiError.meta[field] && apiError.meta[field].length > 0) {
                            addressErrors[field] = apiError.meta[field];
                            hasAddressError = true;
                        }
                    });

                    // If there are address errors, emit them
                    if (hasAddressError) {
                        emit('shipping-validation-error', addressErrors);
                    }

                    // Set the general error message
                    if (hasPromotionError) {
                        errorMessage.value = 'There was an issue with your promotion code. Please check or remove it and try again.';
                    } else {
                        errorMessage.value = apiError.detail || apiError.title || 'Payment failed. Please check your information.';
                    }
                } else {

                    // Use detail or title for general errors
                    errorMessage.value = apiError.detail || apiError.title || 'Payment failed. Please try again.';
                }
            } else {

                // Fallback for non-API errors
                errorMessage.value = error.message || 'Payment failed. Please try again.';
            }

            // Emit the payment error event
            emit('payment-error', { message: errorMessage.value });
        } finally {
            isLoading.value = false;
        }
    };

    // Cleanup on unmount
    onBeforeUnmount(() => {
        stripe.destroyElements();
        isElementMounted.value = false;
    });

</script>

<style scoped>
.bg-navy-blue {
    background-color: #000033;
}

.bg-navy-blue-light {
    background-color: #000044;
}

.bg-flushing-pink {
    background-color: #FF1493;
}
</style>
